import React from 'react';

// Tools
import parse from 'html-react-parser';

// CSS
import * as Styles from './css/InfoBar.module.css';

function InfoBar(props) {
	const {
		html,
	} = props;

	if (!html) return null;

	return (
		<section className={Styles.root}>
			<div className={Styles.content}>
				{parse(html)}
			</div>
		</section>
	);
}

export default InfoBar;
