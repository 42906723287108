import React, { useContext } from 'react';

import { myContext } from '../components/Provider';

// CSS
import * as Styles from './css/press-room.module.css';

// Components
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import TwoColContent from '../components/TwoColContent';
import InfoBar from '../components/InfoBar';
import PressRoomCards from '../components/PressRoomCards';

function PressRoomPage() {
	const { ukPressData } = useContext(myContext);

	const data = ukPressData?.data[0] || {};
	const {
		uk_press_eyebrow,
		uk_press_title,
		features = [],
		uk_press_info_bar,
		media_mentions = [],
	} = data;

	const pageHeading = {
		eyebrow: uk_press_eyebrow,
		title: uk_press_title,
	};

	const twoColContentItems = features.map(item => {
		return {
			image: {
				src: `https://content.pilates.com/${ item.uk_press_image }`,
			},
			title: item.uk_press_title,
			linkUrl: item.uk_press_url,
			linkLabel: item.uk_press_link_label,
		};
	});

	const pressRoomCardsData = media_mentions.map(item => {
		return {
			date: item.uk_press_date,
			title: item.uk_press_title,
			image: {
				src: `https://content.pilates.com/${ item.uk_press_logo_image }`,
			},
			link: {
				label: item.uk_press_link_label,
				url: item.uk_press_url,
			},
		};
	});

	return (
		<Layout title={`Press Room`}>
			<div className={Styles.main}>
				<PageHeading {...pageHeading} />
				<TwoColContent items={twoColContentItems} />
				<InfoBar html={uk_press_info_bar} />
				<PressRoomCards items={pressRoomCardsData} />
			</div>
		</Layout>
	);
}

export default PressRoomPage;
