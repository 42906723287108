import React, { useState } from 'react';

// CSS
import * as Styles from './css/PressRoomCards.module.css';

// Components
import Button from './Button';

const PressRoomCards = props => {
	const {
		items = [],
	} = props;
	const [visibleCards, setVisibleCards] = useState(6);

	const handleClick = () => {
		setVisibleCards(prev => prev + 8);
	};

	if (!items.length) return null;

	return (
		<div className={Styles.root}>
			<div className={Styles.container}>
				<ul className={Styles.cards}>
					{items.map((card, i) => {
						const {
							date, image, title, link,
						} = card;
						return (
							<li
								key={i} // eslint-disable-line
								className={`${ Styles.card } ${ i < visibleCards && `${ Styles.cardVisible }` }`}
							>
								<div className={Styles.cardContainer}>
									<p className={Styles.cardDate}>{ date }</p>
									<div className={Styles.cardImage}>
										<img
											src={image.src}
											alt={image.alt || `image-${ i + 1 }`}
										/>
									</div>
									<div className={Styles.cardBody}>
										<p className={Styles.cardBodyTitle}>{ title }</p>
										<a
											className={Styles.cardBodyLink}
											href={link.url}
											target={'_blank'}
											rel={'noreferrer'}
										>
											<span>
												{link.label}
											</span>
										</a>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
				{visibleCards < items.length && (
					<div
						className={Styles.loadButton}
					>
						<Button
							type={'button'}
							action={handleClick}
							text={'Load More'}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default PressRoomCards;
