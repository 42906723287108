import React from 'react';

// CSS
import * as Styles from './css/PageHeading.module.css';

function PageHeading(props) {
	const {
		eyebrow,
		title,
	} = props;

	return (
		<div className={Styles.root}>
			{eyebrow && (
				<p className={Styles.eyebrow}>{eyebrow}</p>
			)}
			<h1 className={Styles.title}>{title}</h1>
		</div>
	);
}

export default PageHeading;
