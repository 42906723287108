import React from 'react';

// CSS
import * as Styles from './css/TwoColContent.module.css';

// Components
import Button from './Button';

function TwoColContent(props) {
	const {
		items = [],
	} = props;

	if (!items.length) return null;

	return (
		<section className={Styles.root}>
			<div className={Styles.cols}>
				{items.map((item = {}, i) => {
					const {
						title,
						image,
						linkUrl,
						linkLabel,
					} = item;

					return (
						<a
							key={title}
							className={Styles.col}
							href={linkUrl}
							target={'_blank'}
							rel={'noreferrer'}
						>
							<img
								src={image.src}
								alt={image.alt || `image-${ i + 1 }`}
							/>
							<h3>{title}</h3>
							<Button type={'button'} text={linkLabel} />
						</a>
					);
				})}
			</div>
		</section>
	);
}

export default TwoColContent;
